/**
 * Boolean Options
 */
export const BOOLEAN_OPTIONS = [
  {
    value: true,
    label: 'Enabled',
  },
  {
    value: false,
    label: 'Disabled',
  },
];
